/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import e from "../core/Accessor.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
import { getLocale as o, getLanguage as a } from "../intl/locale.js";
const p = new Set(["ar", "bs", "ca", "hr", "cs", "da", "nl", "en", "et", "fi", "fr", "de", "el", "he", "hu", "id", "it", "ja", "ko", "lv", "lt", "nb", "pl", "ro", "ru", "sr", "es", "sv", "th", "tr", "uk", "vi"]);
let c = class extends e {
  constructor(r) {
    super(r), this.id = null, this.language = null, this.places = null, this.serviceUrl = "https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/webmaps", this.worldview = null;
  }
  getLanguageParamValue() {
    if (this.language) return this.language;
    const r = o().toLowerCase();
    switch (r) {
      case "pt-br":
        return "pt-BR";
      case "pt-pt":
        return "pt-PT";
      case "zh-cn":
        return "zh-CN";
      case "zh-hk":
        return "zh-HK";
      case "zh-tw":
        return "zh-TW";
    }
    const e = a(r);
    return "pt" === e ? "pt-BR" : e && p.has(e) ? e : "global";
  }
};
r([s()], c.prototype, "id", void 0), r([s()], c.prototype, "language", void 0), r([s()], c.prototype, "places", void 0), r([s()], c.prototype, "serviceUrl", void 0), r([s()], c.prototype, "worldview", void 0), c = r([t("esri.support.BasemapStyle")], c);
const i = c;
export { i as default };